import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"
import { Form, Line } from "../components/elements"
import { CatchyHeadings, Paragraphs, Title } from "../components/typography"

export default function Contact() {
  return (
    <Layout>
      <SEO title="Contact" />
      <HeaderSection bgColor="#FFF8E1">
        <Title>Ready to grow your business?</Title>
        <Wrapper>
          <Form />
        </Wrapper>
      </HeaderSection>
      <Line />
      <AddressSection>
        <MelbourneSection>
          <Top>
            <CatchyHeadings>Melbourne</CatchyHeadings>
          </Top>
          <Paragraphs>
            Level 1, 80-82 Bell Street,
            <br /> Heidelberg Heights,
            <br /> VIC 3081
          </Paragraphs>
          <MapFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3154.543046402875!2d145.05104071584304!3d-37.75386313839225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64681665f1f39%3A0x89253d1d05cb469b!2sOffice%3A%20Level%201%2C%2080%2F82%20Bell%20St%2C%20Heidelberg%20Heights%20VIC%203081!5e0!3m2!1sen!2sau!4v1600132155299!5m2!1sen!2sau"></MapFrame>
        </MelbourneSection>
        <SydneySection>
          <Top>
            <CatchyHeadings>Sydney </CatchyHeadings>
          </Top>
          <Paragraphs>
            Suite 3, 55 Phillip Street,
            <br /> Parramatta,
            <br /> NSW 2150
          </Paragraphs>
          <MapFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.984052417801!2d151.00283201574385!3d-33.81272462358303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a31ebfa41f03%3A0xa2cdd8379050408b!2s3%2F55%20Phillip%20St%2C%20Parramatta%20NSW%202150!5e0!3m2!1sen!2sau!4v1600133722078!5m2!1sen!2sau"></MapFrame>
        </SydneySection>
      </AddressSection>
    </Layout>
  )
}

const HeaderSection = styled.header`
  width: 100%;
  min-height: 110vh;
  height: auto;
  padding: 3rem 1rem;
  background-color: ${props =>
    props.bgColor || props.theme.procolors.digitalLight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto auto;
  text-align: center;
`
const Wrapper = styled.div`
  width: 45%;
  height: auto;
  margin: 2rem auto;
  padding: 3rem 1.5rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.color.light};
  @media only screen and (max-width: 75em) {
    width: 55%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 75%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 40%;
  }
`

const AddressSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`
const MelbourneSection = styled.div`
  padding: 3rem 3rem;
`
const SydneySection = styled.div``

const MapFrame = styled.iframe`
  min-height: 40rem;
  width: 40rem;
  padding: 3rem 0;
  border-radius: 1rem;
  @media only screen and (max-width: 37.5em) {
    min-height: 40rem;
    width: 95%;
    padding: 2rem 0;
  }
`

const Top = styled.div`
  padding: 1rem 0;
`
